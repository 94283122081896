import { render, staticRenderFns } from "./Product.vue?vue&type=template&id=bcc7bffa&scoped=true&lang=pug"
import script from "./Product.vue?vue&type=script&lang=js"
export * from "./Product.vue?vue&type=script&lang=js"
import style0 from "./Product.vue?vue&type=style&index=0&id=bcc7bffa&prod&lang=scss"
import style1 from "./Product.vue?vue&type=style&index=1&id=bcc7bffa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcc7bffa",
  null
  
)

export default component.exports