<template lang="pug">
#transducers
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 Fetal Transducers
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_new_replacement_parts_for_Fetal_medical_equipment.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p On our brand new replacement parts appearance and functionality are equivalent to OEMs. With us you enjoy up to 50% savings compared to other suppliers and we have most items in stock and ready to ship. We carry replacement parts for the following brands of Fetal Transducers: Philips/Avalon, GE/Corometrics, Huntleigh-Sonicaid, and Edan.
        .item-wrap(v-for="(sp, i) in transducersData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
    components: {
        AboutProductItem,
        MissionPromiseStatement,
        Footer
    },
    data: () => ({
        transducersData: [
            {
              title: "Philips/Avalon",
              models: ["M2734B", "M2735A", "M2736A"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-Parts-Avalon.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KTF_007_40.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KGF_F007.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KGF_M007.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-SP_FUSTC_PH03.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KLF_007_02.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KTF_008_40.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KGF_F008.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KGF_M008.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KTF_009_40.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KGF_F009.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-avalon-KGF_M009.jpg"
              ],
              popularProducts: [
                  {
                      number: "KTF_007_40",
                      description: "Transducer Kit as Shown Above, for TOCO M2734B",
                      brands: "",
                      references: "",
                      package: "1 set/bag"
                  },
                  {
                      number: "KTF_008_40",
                      description: "Transducer Kit as Shown Above, for TOCO+ M2735A",
                      brands: "",
                      references: "",
                      package: "1 set/bag"
                  },
                  {
                      number: "KTF_009_40",
                      description: "Transducer Kit as Shown Above, for Ultrasound M2736A",
                      brands: "",
                      references: "",
                      package: "1 set/bag"
                  },
                  {
                      number: "KTF_009_40",
                      description: "Cable Assembly, for M2734B, M2735A, M2736A",
                      brands: "",
                      references: "",
                      package: "1 set/bag"
                  },
                  {
                      number: "KTF_009_40",
                      description: "Top Case, for TOCO M2734B",
                      brands: "",
                      references: "",
                      package: "1 set/bag"
                  },
                  {
                      number: "KGF_F007",
                      description: "Bottom Case, for TOCO M2734B",
                      brands: "",
                      references: "",
                      package: "1 set/bag"
                  },
                  {
                      number: "KGF_F009",
                      description: "Bottom Case, for Ultrasound M2736A",
                      brands: "",
                      references: "",
                      package: "1 set/bag"
                  }
              ],
            },
            {
              title: "Philips",
              models: ["M1355A", "M1356A"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-parts-Philips.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-philips-KTF_001_50.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-philips-SP_FUS_PH01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-philips-KGF_M001.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-philips-KLF_001_01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/parts-fetal-philips-KTF_002_50.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-philips-SP_FTC_PH01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-philips-KGF_F002.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-philips-M07_T001.jpg"
              ],
            },
            {
              title: "GE Corometrics",
              models: ["2264HAX", "2264LAX", "5700HAX", "5700LAX"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-parts-GE-Corometrics.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-GE-KTF_003_50.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-GE-SP_FUS_GE01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-GE-KTF_006_50.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-GE-SP_FTC_GE01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-GE-KLF_004_06.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-fetal-GE-M02_A001.jpg"
              ],
            },
            {
              title: "Huntleigh-Sonicaid",
              models: ["8400-6919", "8400-6919L", "8400-6920", "8400-6920L", "ACC-OBL-009", "ACC-OBL-009L"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-parts-huntleigh.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/SP_FUS_SC01_WS.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/KGF_F010.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/SP_FUS_SC02_WS.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/KGF_F011.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/SP_FTC_SC01_WS.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/KGF_M012L.jpg",
              ],
            },
            {
              title: "Edan",
              models: ["MS3-31527(B)", "02 01 210259", "MS3-109301(D)", "02 01 210256"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-parts-edan.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/SP_FTC_ED01_WS.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/KGF_M014TC.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/KLF_014_10.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/SP_FUS_ED01_WS.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/KGF_M014US.jpg"
              ],
            }
        ]
    })
  }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>